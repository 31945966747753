import Card from "../../components/Card";
import CustomSelect from "../../components/CustomSelect";
import Table from "../../components/Table";
import moment from "moment";

import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import ProductTable from "../../components/Table/ProductTable";
import SolicitationTable from "../../components/Table/SolicitationTable";
import { ProductsProps } from "../../types/Products";
import { SolicitationsProps } from "../../types/Solicitations";
import { Pagination } from "../../components/Pagination";

export default function Dashboard() {
    const [solicitationList, setSolicitations] = useState<any[]>([]);

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);

    const [searchDateStart, setSearchDateStart] = useState<any>("");
    const [searchDateEnd, setSearchDateEnd] = useState<any>("");
    console.log(
        "date",
        searchDateStart && searchDateEnd !== ""
            ? moment(searchDateStart).format("DD/MM/YYYY") +
                  "-" +
                  moment(searchDateEnd).format("DD/MM/YYYY")
            : ""
    );

    // ?days=01/11/2022-22/11/2022
    const getSolicitations = useCallback(async () => {
        setSolicitations([]);
        try {
            const { data } = await api.get(
                `solicitations?page=${page}&factory_id=${user?.id}&days=${
                    searchDateStart && searchDateEnd !== ""
                        ? moment(searchDateStart).format("DD/MM/YYYY") +
                          "-" +
                          moment(searchDateEnd).format("DD/MM/YYYY")
                        : ""
                }`
            );

            console.log(data.list);

            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setSolicitations(data.list);
        } catch {
            handleLogOut();
        }
    }, [page, user, searchDateStart, searchDateEnd]);

    useEffect(() => {
        getSolicitations();
    }, [reloadData, page, searchDateStart, searchDateEnd]);

    return (
        <div>
            {/* <iframe
                className="w-full h-screen scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-blue-300 overflow-y-scroll"
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=FNT6_6O2Mk-pvULSj8gR8X5iW_7vxBxIq9bfwhjr2HxUOVdZSjRXVEZPVTBHRzU5T003TlJaSlozMCQlQCN0PWcu&embed=true"
                // frameborder="0"
                // marginwidth="0"
                // marginheight="0"
                // style="border: none; max-width:100%; max-height:100vh"
                // allowfullscreen
                // webkitallowfullscreen
                // mozallowfullscreen
                // msallowfullscreen
            /> */}

            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Solicitações de inspeção</h1>
                <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Nova solicitação" />
                </Link>
            </div>
            <div className="col-span-6 sm:col-span-3 mt-4">
                <label
                    htmlFor="date_call"
                    className="block text-sm font-medium text-gray-700"
                >
                    Pesquise por data
                </label>
                <div className="flex gap-3 items-center">
                    <input
                        type="date"
                        name="date_call"
                        id="date_call"
                        autoComplete="given-date"
                        onChange={(e) => setSearchDateStart(e.target.value)}
                        className="mt-1 block w-36 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                    />
                    <div className="">até</div>
                    <input
                        type="date"
                        name="date_call"
                        id="date_call"
                        min={searchDateStart}
                        autoComplete="given-date"
                        onChange={(e) => setSearchDateEnd(e.target.value)}
                        className="mt-1 block w-36 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                    />
                </div>
            </div>
            {solicitationList.length ? (
                <>
                    <SolicitationTable list={solicitationList} />
                    <Pagination
                        initPage={1}
                        totalPage={lastPage}
                        page={page}
                        setPage={setPage}
                        path={`products`}
                    />
                </>
            ) : (
                <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                No momento não temos inspeções agendadas
                            </h1>

                            <p className="mt-2 text-base text-gray-500"></p>
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
}
